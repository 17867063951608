import React, { useEffect, lazy, Suspense } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import {
  ALL_GAME_PAGE_CODE,
  DOWNLOAD_PAGE_CODE,
  EMAIL_CHANGE_PAGE_CODE,
  EMAIL_VERIFICATION_CODE,
  EVENT_PAGE_CODE,
  FIND_ID_COMPLETE_CODE,
  FIND_ID_PAGE_CODE,
  FIND_PW_PAGE_CODE,
  GOOGLE_OTP_APPLYVERIFY_CODE,
  GOOGLE_OTP_APPLY_CODE,
  GOOGLE_OTP_COMPLETE_CODE,
  GOOGLE_OTP_INSTALL_CODE,
  GOOGLE_OTP_LEAVEVERIFY_CODE,
  GOOGLE_OTP_LEAVE_CHECK_CODE,
  GOOGLE_OTP_LEAVE_CODE,
  GOOGLE_OTP_LEAVE_COMPLETE_CODE,
  GOOGLE_OTP_LEAVE_EMAIL_CODE,
  GOOGLE_OTP_SETTING_CODE,
  HISTORY_PAGE_CODE,
  INQUIRY_LIST_CODE,
  INQUIRY_VIEW_CODE,
  JP_TERMS_ACT_CODE,
  JP_TERMS_LAW_CODE,
  LAUNCHER_SIGN_IN_APPLE_CODE,
  LAUNCHER_SIGN_IN_CODE,
  LAUNCHER_SIGN_IN_FACEBOOK_CODE,
  LAUNCHER_SIGN_IN_GOOGLE_CODE,
  LEAVE_COMPLETE,
  LEAVE_VFUN_CODE,
  LIBRARY_PAGE_CODE,
  MAIN_HOME_PAGE_CODE,
  MEMBER_INQUIRY_CODE,
  MOBILE_DETAIL_PAGE_CODE,
  MOBILE_FAQ_HERO_PAGE_CODE,
  MOBILE_FAQ_PAGE_CODE,
  MOBILE_INQUIRY_CODE,
  MOBILE_LIST_PAGE_CODE,
  MOBILE_PRIVACY_CODE,
  MOBILE_TERMS_CODE,
  MY_INFO_CHECK_EMAIL_CODE,
  MY_INFO_CODE,
  MY_STAMP_PAGE_CODE,
  NEWS_PAGE_CODE,
  NON_MEMBER_INQUIRY_CODE,
  PASSWORD_CHANGE_PAGE_CODE,
  POINT_SHOP_PAGE_CODE,
  POPULAR_GAME_PAGE_CODE,
  REDIRECT_PAGE,
  SIGN_IN_PAGE_CODE,
  SIGN_UP_COMPLETE_CODE,
  SIGN_UP_PAGE_CODE,
  SNS_CHANGE_EMAIL,
  SNS_LOGIN_CODE,
  STAMP_PAGE_CODE,
  VFUN_PRIVACY_CODE,
  VFUN_SIGN_UP_PAGE_CODE,
  VFUN_SUPPORT_CODE,
  VFUN_TERMS_CODE,
  VFUN_WITH_DRAW_CODE
} from "./contant";
import { browserName } from "react-device-detect";
import Main from "pages/Main";
import NotFound from "pages/notFound/NotFound";
import NotSupportPage from "pages/notSupport/NotSupportPage";
import WrapLoading from "components/loading/WrapLoading";
import SignUpComplete from "pages/AutoPage/SignUpComplete";
import MyinfoCheckEmail from "pages/AutoPage/MyinfoCheckEmail";
import VfunWithDraw from "pages/AutoPage/VfunWithDraw";
import SnsChangeEmail from "pages/AutoPage/SnsChangeEmail";
import SnsSignUp from "pages/membership/SnsSignUp";
import LauncherSignin from "pages/AutoPage/LauncherSignin";
import LauncherFacebooklogin from "pages/AutoPage/LauncherFacebooklogin";
import LauncherGooglelogin from "pages/AutoPage/LauncherGooglelogin";
import LauncherApplelogin from "pages/AutoPage/LauncherApplelogin";
import Download from "pages/game/Download";
import MobileList from "pages/community/MobileList";
import MobileDetail from "pages/community/MobileDetail";
import MobileFAQ from "pages/customer/MobileFAQ";
import MobileInquiry from "pages/customer/MobileInquiry";
import OTPComplete from "pages/otp/OTPComplete";
import OTPLeave from "pages/otp/OTPLeave";
import OTPLeaveCheck from "pages/otp/OTPLeaveCheck";
import OTPLeaveEmail from "pages/otp/OTPLeaveEmail";
import OTPLeaveVerify from "pages/otp/OTPLeaveVerify";
import OTPLeaveComplete from "pages/otp/OTPLeaveComplete";
import MobileTerms from "pages/customer/MobileTerms";
import MobilePrivacy from "pages/customer/MobilePrivacy";
import RedirectPage from "pages/AutoPage/RedirectPage";
import JpActTerms from "pages/customer/JpActTerms";
import JpLawTerms from "pages/customer/JpLawTerms";
import OptionRoute from "./OptionRoute";
import Teaser from "pages/Teaser/Teaser";

// games
const AllGames = lazy(() => import("pages/game/AllGames"));
const PopularGames = lazy(() => import("pages/game/PopularGames"));

// stamp
const Mission = lazy(() => import("pages/stamp/Mission"));
const PointShop = lazy(() => import("pages/stamp/PointShop"));
const MyStamp = lazy(() => import("pages/stamp/MyStamp"));
const History = lazy(() => import("pages/stamp/History"));

// membership
const SignUp = lazy(() => import("pages/membership/SignUp"));
const VfunSignUp = lazy(() => import("pages/membership/VfunSignUp"));
const SignIn = lazy(() => import("pages/membership/SignIn"));
const PasswordChange = lazy(() => import("pages/membership/PasswordChange"));
const MyInfo = lazy(() => import("pages/membership/MyInfo"));
const LeaveVfun = lazy(() => import("pages/membership/LeaveVfun"));
const LeaveComplete = lazy(() => import("pages/membership/LeaveComplete"));
const FindPW = lazy(() => import("pages/membership/FindPW"));
const FindID = lazy(() => import("pages/membership/FindID"));
const FindIDComplete = lazy(() => import("pages/membership/FindIDComplete"));
const EmailVerification = lazy(
  () => import("pages/membership/EmailVerification")
);
const EmailChange = lazy(() => import("pages/membership/EmailChange"));

// support
const Support = lazy(() => import("pages/customer/Support"));
const VfunTerms = lazy(() => import("pages/customer/VfunTerms"));
const VfunPrivacy = lazy(() => import("pages/customer/VfunPrivacy"));
const NonMemberInquiry = lazy(() => import("pages/customer/NonMemberInquiry"));
const MemberInquiryList = lazy(
  () => import("pages/customer/MemberInquiryList")
);
const MemberInquiry = lazy(() => import("pages/customer/MemberInquiry"));
const InquiryView = lazy(() => import("pages/customer/InquiryView"));

// library
const Library = lazy(() => import("pages/Llibrary/Library"));

// community
const Event = lazy(() => import("pages/community/Event"));
const News = lazy(() => import("pages/community/News"));

// otp
const OTPApply = lazy(() => import("pages/otp/OTPApply"));
const OTPInstall = lazy(() => import("pages/otp/OTPInstall"));
const OTPSetting = lazy(() => import("pages/otp/OTPSetting"));
const OTPApplyVerify = lazy(() => import("pages/otp/OTPApplyVerify"));

const PageRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.pathname === "/home" && navigate("/");
  }, []);

  // 코드 변경으로 인해 다시 확인해보기
  if (browserName === "IE") {
    return <NotSupportPage />;
  }

  return (
    <Suspense fallback={<WrapLoading />}>
      <Routes>
        {/* 로그인 한 사람 */}
        <Route
          path={MY_STAMP_PAGE_CODE}
          element={
            <OptionRoute loginRequired>
              <MyStamp />
            </OptionRoute>
          }
        />
        <Route
          path={HISTORY_PAGE_CODE}
          element={
            <OptionRoute loginRequired>
              <History />
            </OptionRoute>
          }
        />
        <Route
          path={MY_INFO_CODE}
          element={
            <OptionRoute loginRequired>
              <MyInfo />
            </OptionRoute>
          }
        />
        <Route
          path={MY_INFO_CHECK_EMAIL_CODE}
          element={
            <OptionRoute loginRequired>
              <MyinfoCheckEmail />
            </OptionRoute>
          }
        />
        <Route
          path={EMAIL_VERIFICATION_CODE}
          element={
            <OptionRoute loginRequired>
              <EmailVerification />
            </OptionRoute>
          }
        />
        <Route
          path={GOOGLE_OTP_APPLY_CODE}
          element={
            <OptionRoute loginRequired>
              <OTPApply />
            </OptionRoute>
          }
        />
        <Route
          path={GOOGLE_OTP_INSTALL_CODE}
          element={
            <OptionRoute loginRequired>
              <OTPInstall />
            </OptionRoute>
          }
        />
        <Route
          path={GOOGLE_OTP_SETTING_CODE}
          element={
            <OptionRoute loginRequired>
              <OTPSetting />
            </OptionRoute>
          }
        />
        <Route
          path={GOOGLE_OTP_APPLYVERIFY_CODE}
          element={
            <OptionRoute loginRequired>
              <OTPApplyVerify />
            </OptionRoute>
          }
        />
        <Route
          path={GOOGLE_OTP_COMPLETE_CODE}
          element={
            <OptionRoute loginRequired>
              <OTPComplete />
            </OptionRoute>
          }
        />
        <Route
          path={GOOGLE_OTP_LEAVE_CODE}
          element={
            <OptionRoute loginRequired>
              <OTPLeave />
            </OptionRoute>
          }
        />
        <Route
          path={GOOGLE_OTP_LEAVE_CHECK_CODE}
          element={
            <OptionRoute loginRequired>
              <OTPLeaveCheck />
            </OptionRoute>
          }
        />
        <Route
          path={GOOGLE_OTP_LEAVE_EMAIL_CODE}
          element={
            <OptionRoute loginRequired>
              <OTPLeaveEmail />
            </OptionRoute>
          }
        />
        <Route
          path={GOOGLE_OTP_LEAVEVERIFY_CODE}
          element={
            <OptionRoute loginRequired>
              <OTPLeaveVerify />
            </OptionRoute>
          }
        />
        <Route
          path={GOOGLE_OTP_LEAVE_COMPLETE_CODE}
          element={
            <OptionRoute loginRequired>
              <OTPLeaveComplete />
            </OptionRoute>
          }
        />
        <Route
          path={EMAIL_CHANGE_PAGE_CODE}
          element={
            <OptionRoute loginRequired>
              <EmailChange />
            </OptionRoute>
          }
        />
        <Route
          path={SNS_CHANGE_EMAIL}
          element={
            <OptionRoute loginRequired>
              <SnsChangeEmail />
            </OptionRoute>
          }
        />
        <Route
          path={LEAVE_VFUN_CODE}
          element={
            <OptionRoute loginRequired>
              <LeaveVfun />
            </OptionRoute>
          }
        />
        <Route
          path={LEAVE_COMPLETE}
          element={
            <OptionRoute loginRequired>
              <LeaveComplete />
            </OptionRoute>
          }
        />
        <Route
          path={VFUN_WITH_DRAW_CODE}
          element={
            <OptionRoute loginRequired>
              <VfunWithDraw />
            </OptionRoute>
          }
        />
        <Route
          path={INQUIRY_LIST_CODE}
          element={
            <OptionRoute loginRequired>
              <MemberInquiryList />
            </OptionRoute>
          }
        />
        <Route
          path={MEMBER_INQUIRY_CODE}
          element={
            <OptionRoute loginRequired>
              <MemberInquiry />
            </OptionRoute>
          }
        />
        <Route
          path={INQUIRY_VIEW_CODE}
          element={
            <OptionRoute loginRequired>
              <InquiryView />
            </OptionRoute>
          }
        />

        {/* 누구나 */}
        <Route path="/main" element={<Main />} />
        <Route path="/" element={<Main />} />
        {/* <Route path="/" element={<Teaser />} /> */}
        <Route path={ALL_GAME_PAGE_CODE} element={<AllGames />} />
        <Route path={POPULAR_GAME_PAGE_CODE} element={<PopularGames />} />
        <Route path={DOWNLOAD_PAGE_CODE} element={<Download />} />
        <Route path={LIBRARY_PAGE_CODE} element={<Library />} />
        <Route path={STAMP_PAGE_CODE} element={<Mission />} />
        <Route path={POINT_SHOP_PAGE_CODE} element={<PointShop />} />
        <Route path={VFUN_SUPPORT_CODE} element={<Support />} />
        <Route path={VFUN_TERMS_CODE} element={<VfunTerms />} />
        <Route path={VFUN_PRIVACY_CODE} element={<VfunPrivacy />} />
        <Route path={EVENT_PAGE_CODE} element={<Event />} />
        <Route path={NEWS_PAGE_CODE} element={<News />} />
        <Route path={MOBILE_LIST_PAGE_CODE} element={<MobileList />} />
        <Route path={MOBILE_DETAIL_PAGE_CODE} element={<MobileDetail />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/notfound" element={<NotFound />} />
        <Route path={PASSWORD_CHANGE_PAGE_CODE} element={<PasswordChange />} />
        <Route path={MOBILE_FAQ_PAGE_CODE} element={<MobileFAQ />} />
        <Route path={MOBILE_FAQ_HERO_PAGE_CODE} element={<MobileFAQ />} />
        <Route path={MOBILE_INQUIRY_CODE} element={<MobileInquiry />} />
        <Route path={MOBILE_TERMS_CODE} element={<MobileTerms />} />
        <Route path={MOBILE_PRIVACY_CODE} element={<MobilePrivacy />} />
        <Route path={JP_TERMS_LAW_CODE} element={<JpLawTerms />} />
        <Route path={JP_TERMS_ACT_CODE} element={<JpActTerms />} />

        {/* 로그인 하지 않은 사람 */}
        <Route
          path={FIND_ID_PAGE_CODE}
          element={
            <OptionRoute guestOnly>
              <FindID />
            </OptionRoute>
          }
        />
        <Route
          path={SIGN_UP_PAGE_CODE}
          element={
            <OptionRoute guestOnly>
              <SignUp />
            </OptionRoute>
          }
        />
        <Route
          path={VFUN_SIGN_UP_PAGE_CODE}
          element={
            <OptionRoute guestOnly>
              <VfunSignUp />
            </OptionRoute>
          }
        />
        <Route
          path={SIGN_IN_PAGE_CODE}
          element={
            <OptionRoute guestOnly>
              <Outlet />
            </OptionRoute>
          }
        />
        <Route
          path={FIND_PW_PAGE_CODE}
          element={
            <OptionRoute guestOnly>
              <FindPW />
            </OptionRoute>
          }
        />
        <Route
          path={FIND_ID_COMPLETE_CODE}
          element={
            <OptionRoute guestOnly>
              <FindIDComplete />
            </OptionRoute>
          }
        />
        <Route
          path={NON_MEMBER_INQUIRY_CODE}
          element={
            <OptionRoute guestOnly>
              <NonMemberInquiry />
            </OptionRoute>
          }
        />
        <Route path={SIGN_UP_COMPLETE_CODE} element={<SignUpComplete />} />
        <Route path={SNS_LOGIN_CODE} element={<SnsSignUp />} />
        <Route path={LAUNCHER_SIGN_IN_CODE} element={<LauncherSignin />} />
        <Route
          path={LAUNCHER_SIGN_IN_FACEBOOK_CODE}
          element={<LauncherFacebooklogin />}
        />
        <Route
          path={LAUNCHER_SIGN_IN_GOOGLE_CODE}
          element={<LauncherGooglelogin />}
        />
        <Route
          path={LAUNCHER_SIGN_IN_APPLE_CODE}
          element={<LauncherApplelogin />}
        />
        <Route path={REDIRECT_PAGE} element={<RedirectPage />} />
      </Routes>
    </Suspense>
  );
};

export default PageRoutes;
