import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getAllGames,
  IAllGameData
} from "component/CombineMenu/api/sideMenuList";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { langCodeState, siteTypeState } from "recoil/commonUiAtom";

export default function VFUNGameList({
  isVFUNList,
  inputToggle,
  setInputToggle
}: {
  isVFUNList: boolean;
  inputToggle: boolean;
  setInputToggle: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const langCode = useRecoilValue(langCodeState);
  const [newData, setNewData] = useState<IAllGameData[]>([]);
  const [filterData, setFilterData] = useState<IAllGameData[]>([]);
  const siteType = useRecoilValue(siteTypeState);

  const url = (service_code: string) => {
    if (siteType === "lounge") {
      return `${process.env.REACT_APP_LOUNGE_DNS}/page/${service_code}`;
    }

    switch (service_code) {
      case "fwtr":
        return "https://fwtr.valofe.com/";
      case "at_jp":
        return "https://at.pmang.jp/";
      default:
        return `${process.env.REACT_APP_PLATFORM_DNS}/library?service_code=${service_code}`;
    }
  };

  const [value, setValue] = useState("");

  const { isLoading, data } = useQuery(
    ["allgames", langCode],
    () => getAllGames(langCode),
    { enabled: isVFUNList }
  );

  useEffect(() => {
    if (!isLoading && data?.result === 1) {
      setNewData(data.data);
      setFilterData(data.data);
    }
  }, [isLoading, value]);

  useEffect(() => {
    if (value === "") {
      setFilterData(newData);
    } else {
      let result = newData.filter(
        (i) =>
          i.game_title !== null &&
          i.game_title.toLowerCase().includes(value.toLowerCase())
      );
      setFilterData(result);
    }
  }, [value, newData]);

  if (isLoading) return <div>Loading</div>;

  const dataByGenre = (data: IAllGameData[]) => {
    const groupedData: { [key: string]: IAllGameData[] } = {};
    if (isVFUNList) {
      data.forEach((game: IAllGameData) => {
        if (game.vfun_lounge_flag === "N" && siteType === "lounge") return;

        if (groupedData[game.game_genre]) {
          groupedData[game.game_genre].push(game);
        } else {
          groupedData[game.game_genre] = [game];
        }

        return game;
      });
    }

    return groupedData;
  };

  const isNewGame = (date: string) => {
    const today = new Date();
    const relese = new Date(date);

    let diffTime = relese.getTime() - today.getTime();
    let diffDays = Math.abs(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));

    return diffDays <= 30;
  };

  const groupedData = dataByGenre(filterData);

  return (
    <div>
      <div className="title">
        ALL GAMES
        <div
          className="input-area"
          style={{
            width: inputToggle ? "100%" : "32px",
            borderRadius: inputToggle ? "8px" : "15px"
          }}
        >
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            onClick={() => setInputToggle((prev) => !prev)}
          />
          {inputToggle && (
            <input
              type="text"
              placeholder={t("search_for_games")}
              autoFocus={inputToggle}
              onBlur={() => setInputToggle(false)}
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          )}
        </div>
      </div>

      {Object.keys(groupedData)
        .sort()
        .map((i) => (
          <div key={i}>
            <p className="sub-title">{i}</p>
            <ul>
              {groupedData[i].map((j) => (
                <li key={j.idx}>
                  <a href={url(j.service_code)}>
                    {j.game_title}
                    {isNewGame(j.release_at) && <span>NEW</span>}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
    </div>
  );
}
