import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { withdrawApi } from "api/authApi";
import { t } from "i18next";
import { useCookies } from "react-cookie";
import { redirectURL } from "utils/CommonFunc";
import { useRecoilValue } from "recoil";
import { isLauncherState } from "atoms/userInfoAtom";
import customAlert from "components/common/Alert/customAlert";
import { VFUN_PLATFORM_MAIN } from "utils/config/Config";

function VfunWithDraw() {
  const [searchParams] = useSearchParams();
  const cert_code = searchParams.get("cert_code");
  const user_id = searchParams.get("user_id");
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies();
  const isLancher = useRecoilValue(isLauncherState);

  const alertMove = async () => {
    const result = await customAlert(t("email_authentication_complete_error3"));

    if (result) {
      navigate("/membership/sns_email_auth_confirm");
    }
  };

  useEffect(() => {
    if (cert_code === null || user_id === null) {
      alertMove();
    }

    const fetchData = async () => {
      try {
        let res = await axios.post(withdrawApi, {
          cert_code: cert_code,
          user_id: user_id
        });
        if (res.data.result === 1) {
          customAlert(t("email_authentication_complete"));
          Object.keys(cookies).forEach((keys) => {
            removeCookie(keys, { path: "/", domain: ".valofe.com" });
          });
          redirectURL(VFUN_PLATFORM_MAIN, "_self", isLancher);
        } else if (res.data.result === -99) {
          customAlert(t("log_in_is_required_for_this_service"));
        } else if (res.data.result === -96) {
          customAlert(t("send_verification_fail"));
        }
      } catch (error) {
        console.log("with draw", error);
      }
    };

    fetchData();
  }, []);

  return null;
}

export default VfunWithDraw;
