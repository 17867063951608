import { atom, atomFamily } from "recoil";
import {
  createBooleanAtom,
  createNumberAtom,
  createStringAtom
} from "./commonAtom";

//// UI 요소
export const isLoginModalOpenState = createBooleanAtom(
  "isLoginModalOpenState",
  false
);

export const popUpState = atomFamily({
  key: "pointPopUpState",
  default: false
});

export const videoPopUpState = createBooleanAtom("videoPopUpState", false);
export const imgPopUpState = createBooleanAtom("imgPopUpState", false);
export const popUpNewsState = createBooleanAtom("popUpNewsState", false);
export const smsPopUpState = createBooleanAtom("smsPopUpState", false);
export const snsSharePopUpState = createBooleanAtom(
  "snsSharePopUpState",
  false
);
export const snsQrPopUpState = createBooleanAtom("snsQrPopUpState", false);

export const snsQrState = atom<{ qr: string; url: string }>({
  key: "snsQrState",
  default: {
    qr: "",
    url: ""
  }
});

export const newsDataIdxState = createNumberAtom("newsDataIdxState", 0);
export const wrapLoadingState = createBooleanAtom("wrapLoadingState", false);

// Vfun Sign Up Step
interface IsStepProps {
  one: boolean;
  two: boolean;
  three: boolean;
}

export const isStepState = atom<IsStepProps>({
  key: "isStepState",
  default: {
    one: true,
    two: false,
    three: false
  }
});

// CookieNotice
export const cookieNoticeHiddenState = createBooleanAtom(
  "cookieNoticeHiddenState",
  false
);

// library
export const selectMenuState = createStringAtom("selectMenuState", "");
export const currentSectionState = createStringAtom("currentSectionState", "");
export const preRegisterPopUpState = createBooleanAtom(
  "preRegisterPopUpState",
  false
);

export const landingPopUpState = createBooleanAtom("landingPopUpState", false);

export const eventPopUpDataState = atom<{
  idx: number;
  is_character: string | undefined;
  is_sms: string | undefined;
  pre_event: string;
}>({
  key: "eventPopUpDataState",
  default: {
    idx: 0,
    is_character: undefined,
    is_sms: undefined,
    pre_event: "N"
  }
});

export const couponUrlState = createStringAtom("couponUrlState", "");

export const selectBarCategoryState = atom<{
  country: string;
  num: number;
  code: string;
}>({
  key: "selectBarCategoryState",
  default: {
    country: "",
    num: 0,
    code: ""
  }
});

// 런처 상태

export const gameStatusState = createStringAtom("gameStatusState", "");

// 좋아요
export const isLikeState = createBooleanAtom("isLikeState", false);
export const likeCountState = createNumberAtom("likeCountState", 0);
export const isLikeLoadingState = createBooleanAtom(
  "isLikeLoadingState",
  false
);
