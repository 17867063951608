import { BASE_PATH } from "api/api";
import { isLauncherState } from "atoms/userInfoAtom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useRecoilValue } from "recoil";
import { redirectURL } from "utils/CommonFunc";

const logoutApi = `${BASE_PATH}/api/vfun/logout_proc`;

export const useLogout = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const isLauncher = useRecoilValue(isLauncherState);

  const logout = async () => {
    try {
      await axios.post(logoutApi).then((res) => {
        if (res.data.result === 1) {
          Object.keys(cookies).forEach((keys) => {
            if (keys !== "locale") {
              removeCookie(keys, { path: "/", domain: ".valofe.com" });
            }

            if (isLauncher) {
              window.app.vfunRequestLogout();
            }
            redirectURL(window.location.href, "_self", isLauncher);
          });
        }
      });
    } catch (error) {
      console.log(error, "error");
    } finally {
      window.location.reload();
    }
  };

  return logout;
};
