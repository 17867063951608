import { BASE_PATH } from "./api";

//// popular_games 좋아요
export const gamelikeApi = (service_code: string) =>
  `${BASE_PATH}/api/vfun/${service_code}/gamelike`;

//// Sign UP
export const validateidApi = (id: string) =>
  `${BASE_PATH}/api/vfun/validateid/${id}`;

export const validateemailApi = (email: string) =>
  `${BASE_PATH}/api/vfun/validateemail/${email}`;

export const signupApi = `${BASE_PATH}/api/vfun/signup`;

export const memberCertification = `${BASE_PATH}/api/vfun/member_certification`;

//// Sign IN
export interface ILoginData {
  data: {
    birthday: string;
    check_email: string;
    email: string;
    member_type: string;
    service_code: string;
    sso_info: string;
    sso_info_new: string;
    user_id: string;
    SSOKey: number;
    gender: string;
    country: string;
    auth: number;
    user_profile_key: string;
    expired_at: number;
  };
  result: number;
}

export const refreshTokenApi = `${BASE_PATH}/api/vfun/refresh_token`;

//// Find ID
export const findidApi = `${BASE_PATH}/api/vfun/findid`;

export const findidResultApi = `${BASE_PATH}/api/vfun/findid_result`;

//// Find PW
export const findpasswordApi = `${BASE_PATH}/api/vfun/findpassword`;

//// MyInfo
export const myinfoCheckEmailApi = `${BASE_PATH}/api/vfun/myinfo_check_email`;

export const nickNameApi = (nickName: string) =>
  `${BASE_PATH}/api/vfun/nickname_check/${nickName}`;

export const myinfoUpdateApi = `${BASE_PATH}/api/vfun/myinfo_update`;

export const vfunUseOtpApi = `${BASE_PATH}/api/vfun/otp/updateVfunUseOtp/vfun`;

export const changeEmailApi = `${BASE_PATH}/api/vfun/change_email`;

export const changePwApi = `${BASE_PATH}/api/vfun/change_pw`;

export const myinfoCheckApi = `${BASE_PATH}/api/vfun/myinfo_check`;

export const changeEmailCheckApi = `${BASE_PATH}/api/vfun/change_email_check`;

export const exportMyData = `${BASE_PATH}/api/vfun/export_my_data`;

// Leave VFUN
export const leaveCheckPwApi = `${BASE_PATH}/api/vfun/leave_check_pw`;

export const withdrawCheckApi = `${BASE_PATH}/api/vfun/withdraw_check`;

export const withdrawApi = `${BASE_PATH}/api/vfun/withdraw`;

// inquiry
export const inquiryApi = `${BASE_PATH}/api/vfun/inquiry`;

export const notMemberInquiryApi = `${BASE_PATH}/api/vfun/not_member_inquiry`;

export const mobileInquiryApi = (service_code: string) =>
  `${BASE_PATH}/api/vfun/mobile_customer/inquiry/${service_code}`;

// 소셜로그인
// 페이스북 facebook
export interface IUserInfo {
  state: {
    type: string;
    birthday: string;
    email: string;
    first_name: string;
    id: string;
    last_name: string;
  };
}

export const snsSigninProcApi = `${BASE_PATH}/api/vfun/sns_signin_proc`;

export interface ILauncherSnsLogin {
  birthday: string;
  email: string;
  first_name: string;
  vfun_id: string;
  last_name: string;
  real_id: string;
}

export const launcherSignInProcApi = `${BASE_PATH}/api/auth/LauncherSignInProc`;

export const SnsSignupCheckApi = (sns_type: string, user_id: string) =>
  `${BASE_PATH}/api/vfun/sns_signup_check?sns_type=${sns_type}&user_id=${user_id}`;

// 런처 로그인
export const checkWebTokenApi = `${BASE_PATH}/api/auth/checkWebToken`;

export const launcherSignin = (snstype: string, device: string) =>
  `${BASE_PATH}/api/auth/launcher_signin?snstype=${snstype}&device=${device}`;

export const inputCouponNum = `${BASE_PATH}/api/coupon/inputCouponNum`;
export const checkCharacterApi = (chrName: string) =>
  `${BASE_PATH}/api/coupon/checkCharacterApi/${chrName}`;
