import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import { VFUN_PLATFORM_MAIN } from "utils/config/Config";

function RedirectPage() {
  const [searchParams] = useSearchParams();
  const locale = searchParams.get("locale");
  const ret = searchParams.get("ret");
  const [cookies, setCookie] = useCookies();

  const urlList = [
    "vfun.valofe.com",
    "vfun-lounge.valofe.com",
    "vfun-stage.valofe.com",
    "vfun-lounge.stage.valofe.com"
  ];

  const urlMatch = () => {
    for (let i = 0; i < urlList.length; i++) {
      if (ret?.split("/")[2].includes(urlList[i])) {
        setCookie("locale", locale, { path: "/", domain: ".valofe.com" });
        window.open(ret, "_self");
        return;
      }
      window.open(VFUN_PLATFORM_MAIN, "_self");
    }
  };

  useEffect(() => {
    setCookie("locale", locale, { path: "/", domain: ".valofe.com" });
    urlMatch();
  }, []);

  return null;
}

export default RedirectPage;
