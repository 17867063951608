import { Service_GL } from "../CommonFunc";

//// 사이트 정보 키
export const SERVICE_CODE = "vfun";
export const VERIFY_KEY: string = "ehG.NSdz.qoffhvm!@";
export const ReCAPTCHA_KEY = "6LdXlUgUAAAAAOCZNJrrSYc8xOVZOn8ZcCnRs-7e";

//// UI
export const VFUN_IMAGE_DNS: string = "https://file.qijisoft.com/Valofe_file";

//// 사용자 정보
export const MY_INFO_URL: string =
  process.env.REACT_APP_MEMBER_DNS +
  `${
    Service_GL
      ? "/membership/myinfo?service_code=" +
        SERVICE_CODE +
        "&ret=" +
        window.location.href
      : "/mypage/mypage_auth.asp"
  }`;

// export const SIGN_IN_URL: string =
//   process.env.REACT_APP_MEMBER_DNS +
//   `${
//     Service_GL
//       ? "/membership/signin?service_code=" +
//         SERVICE_CODE +
//         "&ret=" +
//         window.location.href
//       : "/login/login.asp?ret=" + window.location.href
//   }`;

// export const SIGN_IN_URL = (service_code: string) =>
//   `/membership/signin?service_code=${service_code}&ret=${window.location.href}`;

// export const SIGN_UP_URL: string =
//   process.env.REACT_APP_MEMBER_DNS +
//   `${
//     Service_GL
//       ? "/membership/signup?service_code=" +
//         SERVICE_CODE +
//         "&ret=" +
//         window.location.href
//       : "/join/member_join_pre.asp"
//   }`;

export const SIGN_UP_URL = (service_code: string) =>
  `/membership/signup?service_code=${service_code}&ret=${window.location.href}`;

//// 이동 URL
export const BILLING_CHARGE_URL = (
  service_code: string,
  type: number
  // isLauncher: boolean
) => {
  let url = "";
  let urlType = "";

  // type === 1 purchase 구매
  // 2 history 충전내역
  // 3 purchase_history 사용내역

  if (Service_GL) {
    switch (type) {
      case 1:
        urlType = "purchase";
        break;
      case 2:
        urlType = "history";
        break;
      case 3:
        urlType = "purchase_history";
    }

    url = `/api/common/redirect/billing?service_code=${service_code}&type=${urlType}`;
  } else {
    url = "/mycash/charge_info.asp";
  }

  // if (!isLauncher) {
  //   return window.app.vFunOpenWebBrowser(
  //     process.env.REACT_APP_VFUN_API_DNS + url
  //   );
  // }

  return process.env.REACT_APP_VFUN_API_DNS + url;
};

export const PRIVACY_POLICY_URL: string =
  process.env.REACT_APP_PLATFORM_DNS +
  "/customer/g_pc_privacy?service_code=vfun&amplanguage=en";

export const TERMS_OF_AGREEMENT_URL: string =
  process.env.REACT_APP_PLATFORM_DNS +
  "/customer/g_pc_terms?service_code=vfun&amplanguage=en";

export const YOUTUBE_URL: string =
  "https://www.youtube.com/channel/UCBOhYvVCI0InwSoyCWsbPZw/videos";

export const VFUN_SITE: string = "https://www.valofe.com";
export const VFUN_PLATFORM_MAIN: string = `${process.env.REACT_APP_PLATFORM_DNS}/main`;
